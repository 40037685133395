* {
    --bg-color: rgb(39, 39, 39)
}

.start-page {
    background-color: var(--bg-color);
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 50px;
}
.start-page .image {
    text-align: center;
}
.start-page .image img {
    height: 50vh;
    width: 50vh;
    max-width: 90vw;
    max-height: 90vw;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    border-radius: 100%;
}
.start-page .text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    text-align: center;
}
.start-page .text .name {
    color: white;
    font-size: 3em;
    font-family: 'Courier New', Courier, monospace;
}
.start-page .text .desc {
    font-size: 1.5em;
    color: white; 
}
.start-page .text .buttons button {
    min-width: 70px;
    border: 0;
    margin-inline: 2px;
    background: transparent;
    color: azure;
    font-weight: bold;
    
}
.start-page .text a:nth-child(1) {
    border-right: 3px solid white;
}
.start-page .text .buttons button:focus,
.start-page .text .buttons button:active,
.start-page .text .buttons button:hover {
    text-decoration: underline;
}

@media only screen and (min-width: 1000px) {
    .start-page .text .name::before,
    .start-page .text .name::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .start-page .text .name::before {
        height: 70%;
        background-color: var(--bg-color);
        animation: typewritter 4s steps(14) forwards;
    }
    .start-page .text .name::after {
        height: 60%;
        width: 0.10em;
        background: black;
        animation: 
            typewritter 4s steps(14) forwards,
            blink 500ms steps(14) infinite;
    }
    .start-page .text .desc { 
        opacity: 0;
        transform: translateY(50%);
        animation: fadeInUp 500ms ease-out 4.5s forwards;
    }
    .start-page .text .buttons {
        visibility: hidden;
        opacity: 0;
        animation: show 1000ms ease-out 5s forwards;
    }
      }
@media (max-width: 768px) {
    .start-page .text {
        margin-inline: auto;
    }
}
@keyframes typewritter {
     to {
        left: 100%;
     }
}
@keyframes blink {
    to {
        background: transparent;
    }
}
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes show {
    to {
        visibility: visible;
        opacity: 1;
    }
}

.sidepanel-wrapper {
    display: flex;
    position: fixed;
    right: 0;
    top: 100px;
    width: min-content;
    z-index: 100;
}

.side-panel-toggle {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: grid;
    place-items: center;
  
    color: #ffffff;
    background: #009578;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    cursor: pointer;
  }
  
  .side-panel-toggle:hover {
    background: #007960;
  }
  
  .side-panel {
    display: none;
    right: 0;
    width: 200px;
    flex-shrink: 0;
    padding: 20px;
    color: #ffffff;
    background: #009578;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  }

  .side-panel-open {
    display: block;
  }
  .sp-icon-rotate {
    rotate: 180deg
  }

  .side-panel a {
    color: white;
  }
  .side-panel a:hover {
    color: blue;
  }
  .side-panel img {
    width: 180px;
    margin-right: 20px;
  }





























/* .start-page-bg {
    background: url('./bg.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.start-page-bg .container {
    position: relativec;
    background: inherit;
    height: 100%;
    width: 100%;
}

.start-page-bg .container::before {
    background: inherit;
    bottom: 0;
    content: "";
    -webkit-filter: blur(3px);
    filter: blur(3px);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.start-page-bg .container .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.start-page-bg .container .center .name h1 {
    color: white;
    font-size: 3em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
}
.start-page-bg .container .center .name p {
    margin: 0;
    color: white;
}
 */
