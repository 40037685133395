header {
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    justify-content: space-between;
    display: flex;
    align-items: center;
    transition: 600ms;
    padding: 40px 100px;
    z-index: 100;
}
header .home {
    position: relative;
    font-weight: 700;
    color: white;
    text-decoration: none !important;
    font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 600ms;
}
header .home:hover {
    color: white;
}
header ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
header ul li {
    position: relative;
    list-style: none;
}
header ul li a {
    position: relative;
    margin: 0px 15px;
    text-decoration: none;
    color: white;
    margin-inline: 30px;
}
header ul li a:hover {
    color: white;
    text-decoration: underline;
}
header.sticky {
    padding: 5px 100px;
    background: white;
}
header.sticky .home,
header.sticky ul li a {
    color:  black;
}
.navBtn {
    position: fixed;
    width: 30px;
    display: none;
    margin: 1em 0 0 1em;
    z-index: 100;
    cursor: pointer;
}
.navBtn div {
    height: 4px;
    background-color: white;
    margin: 5px 0;
    border-radius: 25px;
    transition: 300ms;
}
nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 500ms;
    padding-top: 60px;
  }
  
nav a {
    padding: 8px 16px 40px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 300ms;
    white-space: nowrap;
}
nav a:hover {
    color: white;
    text-decoration: underline;
}
nav.show {
    transition: 300ms;
    width: 250px;
    max-width: 100vw;
}
@media only screen and (max-width: 1000px) {
    header {
        display: none;
    }
    .navBtn {
        display: block;
    }  
}
@media only screen and (min-width: 1000px) {
    nav.show {
        width: 0;
    }
}