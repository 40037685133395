.description-wrapper {
    background: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.description-wrapper .description {
    max-width: 500px;
    height: fit-content;
    padding: 20px;
    color: azure;
}
.description-wrapper .description .text-card {
    font-size: 1.125rem;
    display: flex;
    margin: 5px;
    position: relative;
}
.description-wrapper .description .text-card svg {
    padding: 10px;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}
.description-wrapper .description .text-card p {
    padding: 10px;
    text-align: justify;
    margin: auto;
}
 
.app {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
  }
  
.c-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    height: 100vh;
}

.c-body {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid rgb(39, 39, 39);
  background-color: black;
  z-index: 5;
}
.c-wrapper span {
  position: absolute;
  color: rgba(240, 255, 255, 0.040);
  z-index: 6;
  transform: translateY(-100%);
  font-family: 'Bebas Neue';
}
.c-band {
  z-index: 1;
  position: absolute;
  width: 80px;
  height: 350px;
  background-color: black;
  border-radius: 30px;
}

.c-hours {
  width: 3px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: 48.5%;
  left: 48.5%;
  transform: translate(-48.5%, -48.5%);
  transform: rotate(360deg);
  transform-origin: bottom right;
  border-radius: 30px;
  z-index: 7;
}

.c-min {
  width: 2px;
  height: 60px;
  background-color: rgb(188, 188, 188);
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(360deg);
  transform-origin: bottom right;
  border-radius: 30px;
  z-index: 9;
}

.c-second {
  width: 1px;
  height: 60px;
  background-color: rgb(255, 5, 5);
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(360deg);
  transform-origin: bottom right;
  border-radius: 30px;
  z-index: 9;
}

.c-dot {
  width: 6px;
  height: 6px;
  background-color: rgb(23, 20, 20);
  border: 2px solid rgb(65, 65, 65);
  position: absolute;
  top: 51%;
  left: 51%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 10;
}

.c-numbers {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  transform: rotate(270deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-numbers > * {
  display: block;
  position: absolute;
  color: white;
  width: 10px;
}
  
@media only screen and (max-width: 1000px) {
    .app {
        display: none;
    }
}